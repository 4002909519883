var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("图片上传输入框")]), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 直接上传 ")]), _c('upload-pic-input', {
    staticStyle: {
      "width": "400px"
    },
    model: {
      value: _vm.picUrl,
      callback: function ($$v) {
        _vm.picUrl = $$v;
      },
      expression: "picUrl"
    }
  }), _c('br'), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 素材中心上传 ")]), _c('upload-pic-input', {
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "material": ""
    },
    model: {
      value: _vm.picUrl,
      callback: function ($$v) {
        _vm.picUrl = $$v;
      },
      expression: "picUrl"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("样式冲突")]), _vm._v(" 在 "), _c('code', [_vm._v("FormItem")]), _vm._v(" 中使用时，建议在该标签上加上 "), _c('code', [_vm._v("class=\"form-noheight\"")]), _vm._v("。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };